import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import checkIcon from '../../assets/icons/check.png';
import not from '../../assets/icons/exl.png';
import media from '../../utils/helpers/media';

const StyledCategoryItem = styled.div`
  .label {
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;

    &.label--bottom {
      margin-bottom: 10px;
    }
  }

  .top {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 20px;

    ${media.phone`
      flex-direction: column;
        
    ;`}

    p {
      font-size: 16px;
    }

    div {
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      width: 324px;

      ${media.phone`
        width: 100%;

    ;`}
    }
    .top--sub {
      margin-right: 47px;

      ${media.phone`  
        margin-right: 0;
    `};
    }
    img {
      width: 34px;
      height: 34px;
      margin-right: 12px;
    }
  }

  h3 {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 27px;
    ${media.phone`  
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
    `};
  }
`;

const NinesBlock = ({ searchValue }) => {
  return (
    <StyledCategoryItem>
      <h3>
        {' '}
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Сколько вы заплатите соседям и за что?"
        />{' '}
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Мы оценим, сколько стоит его потеря. Если пострадало больше, чем ваш
          лимит, то мы заплатим, сколько вы указали при покупке страховки. Если
          ущерб дешевле вашего лимита, то заплатим за все, что пострадало у
          соседа."
        />
      </p>
      <h3>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Я развел костер на балконе, который перешел еще на две квартиры и
          повредил свой паркет. Сколько выплатят мне и каждому из соседей?"
        />
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="К сожалению ни сколько, это потери, которые произошли из-за вашего
          стремления к первооткрывательству, а не случайно."
        />
      </p>
      <div className="top">
        <div className="top--sub">
          <h5 className="label">
            <img src={checkIcon} alt="#" />
            За что платим
          </h5>
          <p className="text">
            <Highlighter
              highlightClassName="highlight"
              searchWords={[searchValue]}
              textToHighlight="Ремонт и все вещи в квартире соседей защищены от любой вашей
          неосторожности, а так же, в случае, если ущебр им будет учинен
          вашими друзьями или близкими"
            />
          </p>
        </div>
        <div>
          <h5 className="label">
            <img src={not} alt="#" />
            Ограничения
          </h5>
          <p className="text">
            <Highlighter
              highlightClassName="highlight"
              searchWords={[searchValue]}
              textToHighlight="Выплата осуществляется в пределах выбранного лимита, суммарно на
          всех пострадавших соседей. Например если лимит составляет 300 000 ₽,
          то 3 соседей получат по 100 000 ₽ каждый."
            />
          </p>
        </div>
      </div>
    </StyledCategoryItem>
  );
};

NinesBlock.propTypes = {
  searchValue: PropTypes.string.isRequired
};

export default NinesBlock;
