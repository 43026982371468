import React, { useState } from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slide from 'react-reveal/Slide';
import vars from '../../utils/styles/vars';

const labels = [
  [
    { label: 'Условия', to: '#how' },
    { label: 'Что защищено', to: '#protect' },
    { label: 'Полис', to: '#docs' },
    { label: 'Вещи', to: '#staff' }
  ],
  [
    { label: 'Ремонт', to: '#recon' },
    { label: 'Соседи', to: '#nextdoor' },
    { label: 'Кабинет', to: '#user' },
    { label: 'Случаи', to: '#cases' }
  ]
];

const StyledLink = styled(AnchorLink)`
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Proxima Nova', sans-serif;
  color: ${vars.colors.primaryColor};
  text-decoration: none;
  cursor: pointer;

  &:link,
  &:active {
    color: ${vars.colors.primaryColor};
  }
`;

const Block = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  span {
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    color: ${vars.colors.primaryColor};
  }
`;

const Container = styled.div`
  margin-bottom: 5px;
  margin-top: -5px;
`;
const MobileLabelList = () => {
  const [activeBlock, setActiveBlock] = useState(0);
  const prevBlock = () => {
    setActiveBlock(0);
  };
  const nextBlock = () => {
    setActiveBlock(1);
  };

  return (
    <Container>
      {activeBlock === 0 && (
        <Slide left>
          <Block>
            {labels[0].map(item => (
              <StyledLink href={item.to} offset="250" key={item.label}>
                {item.label}
              </StyledLink>
            ))}
            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
            <span onClick={nextBlock} role="button" onKeyDown={nextBlock}>
              ...
            </span>
          </Block>
        </Slide>
      )}
      {activeBlock === 1 && (
        <Slide right>
          <Block>
            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
            <span onClick={prevBlock} role="button" onKeyDown={prevBlock}>
              ...
            </span>
            {labels[1].map(item => (
              <StyledLink href={item.to} offset="250" key={item.label}>
                {item.label}
              </StyledLink>
            ))}
          </Block>
        </Slide>
      )}
    </Container>
  );
};

export default MobileLabelList;
