import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import vars from '../styles/vars';
import media from '../helpers/media';

const StyledHeading = styled.h1`
  font-size: 34px;
  letter-spacing: 0.25px;
  color: ${vars.colors.textColor};
  margin-bottom: ${props => (props.center ? '0' : '40px')};
  font-weight: 600;
  text-align: ${props => (props.center ? 'center' : 'unset')};

  ${media.phone`
    font-size: 24px;
    letter-spacing: 0.25px;
    line-height: 34px;
  ;`}
`;

const Heading = ({ text, center }) => {
  return (
    <Fade top>
      <StyledHeading center={center}>{text}</StyledHeading>
    </Fade>
  );
};

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  center: PropTypes.bool.isRequired
};

export default Heading;
