import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import media from '../../utils/helpers/media';

const StyledCategoryItem = styled.div`
  h3 {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 27px;

    ${media.phone`  
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
    `};
  }
`;

const TensBlock = ({ searchValue }) => {
  return (
    <>
      <StyledCategoryItem>
        <h3>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchValue]}
            textToHighlight="Нет страховки в личном кабинете, хотя я точно помню, что покупал"
          />{' '}
        </h3>
        <p>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchValue]}
            textToHighlight="Напишите пожалуйста нам в чат. Мы посмотрим, что могло пойти не так.
          Не переживайте, если вы покупали страховку, то она никуда не
          потерялась."
          />
        </p>
        <h3>
          {' '}
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchValue]}
            textToHighlight="Я не хочу заводить личный кабинет"
          />
        </h3>
        <p>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchValue]}
            textToHighlight="Мы можем выслать полис вам на почту. Просто напишите нам в чат или
          позвоните. Саппорт задаст вам все необходимые вопросы, поможет с
          покупкой и вышлет полис на почту."
          />
        </p>
      </StyledCategoryItem>
    </>
  );
};

TensBlock.propTypes = {
  searchValue: PropTypes.string.isRequired
};

export default TensBlock;
