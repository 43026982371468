import React, { useState } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import vars from '../../utils/styles/vars';
import Button from '../../utils/common/Button';
import CallMeBackModal from './CallMeBackModal';
import media from '../../utils/helpers/media';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 140px 0;
  max-width: 1440px;
  margin: 0 auto;

  ${media.phone`
    flex-direction: column;
    padding: 80px 0;    
  ;`}

  .input {
    width: 416px;
    height: 56px;
    border: 1px solid #d9dce6;
    border-radius: 8px;
    padding: 12px 15px;
    margin-right: 18px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    font-family: 'Proxima Nova', sans-serif;

    ${media.phone`
    flex-direction: column;
    width: 328px;
    margin-bottom: 18px;
    margin-right: 0;
  ;`}
    @media (max-width: 415px) {
      width: 360px;
    }
    @media (max-width: 376px) {
      width: 340px;
    }
    @media (max-width: 320px) {
      width: 300px;
    }
    &:focus {
      outline: none;
      border: 1px solid ${vars.colors.primaryColor};
    }
  }
`;

const Labels = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 70px;

  ${media.phone`
    margin-right: 0;
    margin-bottom: 18px;
  ;`}
  h4 {
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.25px;
    font-weight: 600;

    ${media.phone`
      font-size: 24px;
      line-height: 32px;
  ;`}
  }
  p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 20px;

    ${media.phone`
      font-size: 10px;
      line-height: 16px;
  ;`}
  }
`;

const StyledButton = styled(Button)`
  font-size: 18px;
  line-height: 28px;
  height: 56px;
  width: 296px;

  @media (max-width: 415px) {
    width: 360px;
  }
  @media (max-width: 376px) {
    width: 340px;
  }
  @media (max-width: 320px) {
    width: 300px;
  }
`;

const CallMeBack = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const openModal = () => {
    if (phone === 0) {
      setIsDisabled(true);
    }
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <Wrapper>
      {' '}
      <Labels>
        <h4>Остались вопросы?</h4>{' '}
        <p>Напишите телефон, и мы свяжемся с вами </p>
      </Labels>
      <form onSubmit={handleSubmit}>
        <InputMask
          mask="+7 (999) 999-99-99"
          value={phone}
          onChange={e => setPhone(e.target.value)}
          placeholder="+7 ( _ _ _ ) _ _ _ — _ _ — _ _ "
          className="input"
        />
      </form>
      <StyledButton
        text="Перезвоните мне"
        onClick={openModal}
        disabled={isDisabled}
      />
      {isModalOpen && <CallMeBackModal closeModal={closeModal} />}
    </Wrapper>
  );
};

export default CallMeBack;
