import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Heading from '../utils/common/Heading';
import MainSection from '../components/FAQ/MainSection';
import CallMeBack from '../components/FAQ/CallMeBack';
import media from '../utils/helpers/media';
import useMedia from '../hooks/useMedia';
import pdfIcon from '../assets/icons/google.svg';
import { polisLink } from '../utils/helpers/getters';

const PageWrapper = styled.div`
  padding: 30px 0;
  background: linear-gradient(
    to top,
    rgba(237, 239, 255, 0.37),
    #ffffff,
    #ffffff,
    rgba(237, 239, 255, 0.37),
    rgba(237, 239, 255, 0.37)
  );
  margin: 0 auto;

  ${media.phone`
    background: linear-gradient(to bottom, rgba(237, 239, 255, 0.37), #ffffff,rgba(237, 239, 255, 0.37));
  ;`}
`;

const Download = styled.h4`
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  color: #794cfa;
  justify-content: center;
  cursor: pointer;
  margin-left: 2px;
  margin-bottom: 10px;

  a {
    text-decoration: none;
    color: #794cfa;
    &:focus,
    &:active {
      color: #794cfa;
    }
  }

  &:last-of-type {
    margin-right: 31px;
  }

  @media (max-width: 320px) {
    margin-left: 10px;
  }

  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  .pdf-icon {
    margin-right: 5px;
  }

  span {
    margin-right: 10px;

    @media (max-width: 320px) {
      text-align: center;
    }
  }
`;

const DownloadWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0 5px;
  flex-direction: column;
`;

const IndexPage = () => {
  const small = useMedia('(max-width: 576px)');
  return (
    <Layout>
      <SEO title="Условия и выплаты" />
      <PageWrapper>
        {small && (
          <>
            <Heading center text="Условия и выплаты" />
            <DownloadWrapper>
              <Download>
                <a href={`${polisLink}`}>
                  {' '}
                  <img src={pdfIcon} alt="#" className="pdf-icon" />
                  <span>Условия страхования</span>
                </a>
              </Download>
              <Download>
                <a href={`${polisLink}`}>
                  {' '}
                  <img src={pdfIcon} alt="#" className="pdf-icon left" />
                  <span>Страховой полис</span>
                </a>
              </Download>
            </DownloadWrapper>
          </>
        )}
        <MainSection />
        <CallMeBack />
      </PageWrapper>
    </Layout>
  );
};

export default IndexPage;
