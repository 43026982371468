import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import checkIcon from '../../assets/icons/check.png';
import not from '../../assets/icons/not.png';
import media from '../../utils/helpers/media';

const StyledCategoryItem = styled.div`
  .wrapper {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .promise {
    padding-bottom: 46px;

    p {
      font-family: Proxima Nova;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;

    &.label--bottom {
      margin-bottom: 10px;
    }
  }

  .text {
    font-family: 'Proxima Nova', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    width: 304px;

    ${media.phone`
      width: 100%;
      font-size: 15px;
  ;`}
  }

  .top {
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100%;

    ${media.phone`
      flex-direction: column;
  ;`}

    .top--sub {
      display: flex;
      align-items: start;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      margin-right: 48px;

      ${media.phone`
        margin-bottom: 27px;
        margin-right: 0;
    ;`}
    }
    img {
      width: 34px;
      height: 34px;
      margin-right: 12px;
    }
  }
`;

const FirstBlock = ({ searchValue }) => {
  return (
    <>
      <StyledCategoryItem>
        <div className="wrapper">
          <div className="top">
            <div className="top--sub">
              <h5 className="label">
                <img src={checkIcon} alt="#" />
                За что платим
              </h5>
              <p className="text">
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[searchValue]}
                  textToHighlight="Если имущество пострадает не по вашей вине, например в случае:
                залива, пожара, кражи и вандализма, взрыва газа, стихийных
                бедствий, короткого замыкания"
                />
              </p>
            </div>
            <div>
              <h5 className="label">
                <img src={not} alt="#" />
                За что не платим
              </h5>
              <p className="text">
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[searchValue]}
                  textToHighlight="Манго не компенсирует страховые случаи, которые произошли по
                  вашей вине или халатности. В том числе неосторожность друзей,
                  детей и близких"
                />
              </p>
            </div>
          </div>
        </div>
      </StyledCategoryItem>
    </>
  );
};

FirstBlock.propTypes = {
  searchValue: PropTypes.string.isRequired
};

export default FirstBlock;
