import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import pdfIcon from '../../assets/icons/google.svg';
import media from '../../utils/helpers/media';

const StyledCategoryItem = styled.div`
  h3 {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 27px;

    ${media.phone`
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
  ;`}
  }
`;

const Download = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #794cfa;
  justify-content: start;
  margin-right: 11px;
  margin-left: -11px;
  cursor: pointer;
  width: 100%;

  .pdf-icon {
    width: 40px;
    height: 40px;
    padding: 13px;
  }
`;
const FivesBlock = ({ searchValue }) => {
  return (
    <StyledCategoryItem>
      <h3>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Какие документы нужны для оформления?"
        />
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Мы не требуем никаких документов, чтобы оформить для вас страховку
          квартиры. Все, что нам нужно – адрес, по которому квартира находится, и
          ваш аккаунт в Facebook, чтобы в случае чего мы могли с вами вязаться.
          Правда, классно?"
        />
      </p>
      <h3>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Могу ли я застраховать квартиру своей бабушки, если я не собственник?"
        />
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Да. Это может показаться странным, но любой человек может застраховать
          любую квартиру. А вот деньги может получить только тот, чье имущество
          пострадало. Поэтому - квартиру можете застраховать вы, а деньги получить
          в случае чего - может только бабушка."
        />
      </p>
      <h3>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Делаете ли вы страховки для банка при ипотеке?"
        />
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Пока мы не делаем страховку для банка. Наша страховка выгодна людям и не
          выгодна банкам. Банку необходимо страховать бетонные стены жилища, но
          такие риски бесконечно малы. Мы не предлагаем их в страховке, чтобы не
          увеличивать цену."
        />
      </p>
      <h3>
        {' '}
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Застраховал квартиру 2 года назад. Полис еще действует?"
        />
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Полис действует в течение полугода после окончания. Действие вашего
          закончилось 1.5 года назад"
        />
      </p>
      <h3>
        {' '}
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Где я могу получить бумажный полис с печатью?"
        />
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Мы не делаем бумажные полисы, чтобы страховка была дешевой, а бумага не
          расходовалась почем зря. Ваш полис всегда находится в личном кабинете в
          электронном виде. Вы можете распечатать свой полис оттуда, нажав на
          кнопку 'печать'."
        />
      </p>
      <Download>
        {' '}
        <img src={pdfIcon} alt="#" className="pdf-icon" />
        пример полиса
      </Download>
    </StyledCategoryItem>
  );
};

FivesBlock.propTypes = {
  searchValue: PropTypes.string.isRequired
};

export default FivesBlock;
