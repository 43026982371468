import React from 'react';
import styled from 'styled-components';
import media from '../../utils/helpers/media';

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 728px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  padding: 28px 30px;
  margin-bottom: 10px;
  height: 100%;
  animation-duration: 0.5s;
  animation-name: fadeIn;

  ${media.phone`
    width: 100%;
  ;`}
`;

const WithSearchedValue = ({ component: Component, searchValue, text }) => (
  <Wrapper>
    <Component searchValue={searchValue} text={text} />
  </Wrapper>
);

export default WithSearchedValue;
