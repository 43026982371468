import FirstBlock from './FirstBlock';
import SecondBlock from './SecondBlock';
import FivesBlock from './FivesBlock';
import SevensBlock from './SevensBlock';
import EightsBlock from './EightsBlock';
import NinesBlock from './NinesBlock';
import TensBlock from './TensBlock';
import ElevensBlock from './ElevensBlock';

const data = [
  {
    id: 'how',
    category: 'Условия страхования',
    component: FirstBlock,
    text: `Страховое обещание
    Обещаем защищать вашу квартиру и вещи в ней до 1 000 000 ₽ заплатим вам при наступлении страхового случая за одну вещь не больше 150 000 ₽ за один предмет мебели не больше 300 000 ₽ За что платим Если имущество пострадает не по вашей вине, например в случае: залива, пожара, кражи и вандализма, взрыва газа, стихийных бедствий, короткого замыкания За что не платим Манго не компенсирует страховые случаи, которые произошли по вашей вине или халатности. В том числе неосторожность друзей, детей и близких`
  },
  {
    id: 'protect',
    component: SecondBlock,
    category: 'Что защищено',
    text: `Защищены Все вещи внутри жилья: мебель, электроннобытовая техника, одежда и украшения, до 150 000 ₽ за каждую вещь. Весь ремонт внутри квартиры, окна, стены, полы, трубы и батареи, включая инженерные коммуникации. За что не платим Вещи за пределами вашей квартиры, наличные деньги, поломки бытовой техники, бетонный каркас и несущие стены.
    `
  },
  {
    id: 'docs',
    component: FivesBlock,
    category: 'Страховой полис',
    text: `Какие документы нужны для оформления? Мы не требуем никаких документов, чтобы оформить для вас страховку квартиры. Все, что нам нужно – адрес, по которому квартира находится, и ваш аккаунт в Facebook, чтобы в случае чего мы могли с вами вязаться. Правда, классно? Могу ли я застраховать квартиру своей бабушки, если я не собственник? Да. Это может показаться странным, но любой человек может застраховать любую квартиру. А вот деньги может получить только тот, чье имущество пострадало. Поэтому - квартиру можете застраховать вы, а деньги получить в случае чего - может только бабушка Делаете ли вы страховки для банка при ипотеке? Пока мы не делаем страховку для банка. Наша страховка выгодна людям и не выгодна банкам. Банку необходимо страховать бетонные стены жилища, но такие риски бесконечно малы. Мы не предлагаем их в страховке, чтобы не увеличивать цену. Застраховал квартиру 2 года назад. Полис еще действует?
    Полис действует в течение полугода после окончания. Действие вашего закончилось 1.5 года назад Где я могу получить бумажный полис с печатью? Мы не делаем бумажные полисы, чтобы страховка была дешевой, а бумага не расходовалась почем зря. Ваш полис всегда находится в личном кабинете в электронном виде. Вы можете распечатать свой полис оттуда, нажав накнопку "печать"..`
  },
  {
    id: 'staff',
    component: SevensBlock,
    category: 'Мебель и вещи',
    text: `Как вы узнаете стоимость вещей и ремонта в моей квартире? Ко мне приедет агент? Нам необходимо знать эту стоимость. Цена страховки формируется из величины лимитов, которые вы выбрали. Выше лимиты - выше цена. Вещи мы оцениваем, если что-то случается и оплачиваем все пострадавшее. Украли велосипед, который стоял в общем тамбуре. Мне заплатят? Нет, вещи, которые находятся за пределами квартиры не защищены Грабители повредили замок во входной двери, но в квартиру пробраться не смогли. Страховка это возместит? Да. Входная дверь относится к квартире. В моей квартире из-за пожара пострадал компьютер за 200 000 Р и орхиде за 3 000 Р. Что возместит страховка? Мы выплачиваем максимум 150 000р за одну вещь. Поэтому за компьютер вам заплатим 150 000р, а за орхидею целиком.`
  },
  {
    id: 'recon',
    component: EightsBlock,
    category: 'Ремонт',
    text: `Входит ли в страховку стоимость ремонтных работ? Нет, вещи, которые находятся за пределами квартиры не защищены Сделал перепланировку в квартире, в результате которой обрушилась стена к соседу и разбилась ваза Да. Входная дверь относится к квартире. В моей квартире из-за пожара пострадал компьютер за 200 000 Р и орхидея за 3 000 Р. Что возместит страховка? Мы выплачиваем максимум 150 000р за одну вещь. Поэтому за компьютер вам заплатим 150 000р, а за орхидею целиком.`
  },
  {
    id: 'nextdoor',
    component: NinesBlock,
    category: 'Соседи',
    text: `Сколько вы заплатите соседям и за что?
    Мы оценим, сколько стоит его потеря. Если пострадало больше, чем ваш лимит, то мы заплатим, сколько вы указали при покупке страховки. Если ущерб дешевле вашего лимита, то заплатим за все, что пострадало у соседа.Я развел костер на балконе, который перешел еще на две квартиры и повредил свой паркет. Сколько выплатят мне и каждому из соседе К сожалению ни сколько, это потери, которые произошли из-за вашего стремления к первооткрывательству, а не случайно. За что платим Ремонт и все вещи в квартире соседей защищены от любой вашей неосторожности, а так же, в случае, если ущебр им будет учинен вашими друзьями или близкими Ограничения Выплата осуществляется в пределах выбранного лимита, суммарно на всех пострадавших соседей. Например если лимит составляет 300 000 ₽, то 3 соседей получат по 100 000 ₽ каждый.`
  },
  {
    id: 'user',
    component: TensBlock,
    text: `Напишите пожалуйста нам в чат. Мы посмотрим, что могло пойти не так. Не переживайте, если вы покупали страховку, то она никуда не потерялась. Я не хочу заводить личный кабинет Мы можем выслать полис вам на почту. Просто напишите нам в чат или позвоните. Саппорт задаст вам все необходимые вопросы, поможет с покупкой и вышлет полис на почту`,
    category: 'Личный кабинет'
  },
  {
    id: 'cases',
    component: ElevensBlock,
    category: 'Cлучаи из жизни',
    text: `Антон ушел на работу, забыв закрыть воду в ванной и затопил соседей до первого этажа. Заплатят?Да, но только соседям. Манго не защищает ваши вещи от вас самих, а вот соседские — да. Гости Олега так бурно веселились, что сломали телевизор и дверь в спальню. Заплатят?Нет. Манго не компенсирует то, что было сломано в вашем доме по вашей вине.`
  }
];

export default data;
