import React from 'react';
import styled from 'styled-components';
import coushIcon from '../../assets/icons/furniture.png';
import tvIcon from '../../assets/icons/tv.png';
import stormIcon from '../../assets/icons/storm.png';
import stormIconMob from '../../assets/icons/stormMob.png';
import tvIconMob from '../../assets/icons/tvMobile.png';
import couchIconMob from '../../assets/icons/furnitureMobile.png';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';

const HeroWrapper = styled.div`
  margin-bottom: 40px;
  width: 728px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  padding: 22px 25px 44px 25px;
  animation-duration: 0.5s;
  animation-name: fadeIn;
  ${media.phone`
    width: 100%;
  ;`}
`;

const QouteWrapper = styled.div`
  text-align: left;
  background: #fff;

  ${media.phone`
    width: 100%;
    padding: 0 10px;
  ;`}

  p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 5px;

    ${media.phone`
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      opacity: .7;
      text-align: center;
      margin-top: 10px;
  ;`}
  }
`;

const Quote = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 49px;
  position: relative;

  ${media.phone`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    text-align:center;
    margin-bottom: 10px;
  ;`}
`;

const FeaturesWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;

  ${media.phone`
    width: 100%;
    padding: 0;
    flex-direction: column;
    align-items:center;
    
  ;`}
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-right: 40px;

  ${media.phone`
    align-items: center;
    margin-right: 0;
    margin-top: 39px;
  ;`}

  .text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 600;

    &--couch {
      margin-bottom: 3px;
    }
    &--tv {
      margin-bottom: 2px;
    }
  }

  span {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Proxima Nova', sans-serif;

    ${media.phone`
      font-size: 15px;
  `};
  }

  .icon {
    width: 58px;
    height: 58px;
    margin-bottom: 16px;

    ${media.phone`
      width: 58px;
      height: 58px;
  `};

    &--storm {
      margin-bottom: 0px;
      margin-top: -5px;
    }
    &--couch {
      width: 53px;
      height: 34px;
    }
    &--tv {
      width: 60px;
      height: 35px;
    }
  }
`;

const Hero = () => {
  const small = useMedia('(max-width: 576px)');

  return (
    <HeroWrapper>
      <QouteWrapper>
        <p>Страховое обещание Манго.</p>
        <Quote>Обещаем защищать вашу квартиру и вещи в ней.</Quote>
      </QouteWrapper>
      <FeaturesWrapper>
        <Feature>
          <img
            src={small ? stormIconMob : stormIcon}
            alt="#"
            className="icon icon--storm"
          />
          <h3 className="text">до 1 000 000 ₽</h3>
          <span>Общая сумма выплат</span>
        </Feature>
        <Feature>
          <img
            src={small ? couchIconMob : coushIcon}
            alt="#"
            className="icon icon--couch"
          />
          <h3 className="text text--couch">до 300 000 ₽</h3>
          <span>За один предмет мебели </span>
        </Feature>
        <Feature>
          <img
            src={small ? tvIconMob : tvIcon}
            alt="#"
            className="icon icon--tv"
          />
          <h3 className="text text--tv">до 150 000 ₽</h3>
          <span>За одну вещь </span>
        </Feature>
      </FeaturesWrapper>
    </HeroWrapper>
  );
};

export default Hero;
