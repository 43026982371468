import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import { Swipeable } from 'react-swipeable';
import prevIcon from '../../assets/icons/arrow_left.svg';
import nextIcon from '../../assets/icons/arrow_right.svg';
import useMedia from '../../hooks/useMedia';
import media from '../../utils/helpers/media';

const StyledCategoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  button {
    &:focus {
      outline: none;
    }
  }

  .wrapper {
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${media.phone`
      flex-direction: column; 
      padding: 0;
  ;`}
  }
  .left {
    margin-right: 55px;

    ${media.phone`
      margin-right: 0;
  ;`}
  }

  .prev-icon {
    position: absolute;
    left: -2%;
    top: 30%;

    ${media.phone`
      left: -9%;
      top: 11%;
  ;`}
    @media (max-width: 415px) {
      left: -9%;
    }
    @media (max-width: 376px) {
      left: -12%;
    }
    @media (max-width: 320px) {
      left: -15%;
    }
  }
  .next-icon {
    position: absolute;
    right: -2%;
    top: 30%;

    ${media.phone`
      right: -9%;
      top: 11%;
  ;`}
    @media (max-width: 415px) {
      right: -9%;
    }
    @media (max-width: 376px) {
      right: -12%;
    }
    @media (max-width: 320px) {
      right: -15%;
    }
  }

  h3 {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    width: 265px;

    ${media.phone`
      text-align: center;
      width: 100%;
  ;`}
  }

  p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 27px;
    width: 264px;

    ${media.phone`
      text-align: center;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: '#818A97';
      width: 100%;
  ;`}
  }
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: ${props => (props.active ? '#5A4CFA' : '#CFD8DC')};
  box-shadow: ${props =>
    props.active ? '0px 4px 8px rgba(48, 79, 254, 0.24)' : ''};
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const ElevensBlock = ({ searchValue }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [0, 1, 2];
  const handleActiveSlide = slide => {
    setActiveSlide(slide);
  };
  const prevSlide = () => {
    setActiveSlide((activeSlide + 1) % slides.length);
  };
  const nextSlide = () => {
    setActiveSlide((activeSlide - 1 + slides.length) % slides.length);
  };
  const config = {
    onSwipedLeft: () => prevSlide(),
    onSwipedRight: () => nextSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  };
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');
  return (
    <StyledCategoryItem>
      <div className="wrapper">
        <button onClick={prevSlide} type="button">
          <img
            src={prevIcon}
            alt="#"
            className="prev-icon"
            area-label="prev-icon"
          />
        </button>
        <button onClick={nextSlide} type="button">
          <img
            src={nextIcon}
            alt="#"
            className="next-icon"
            area-label="next-icon"
          />
        </button>
        {large && (
          <>
            <div className="left">
              <h3>
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[searchValue]}
                  textToHighlight="Антон ушел на работу, забыв закрыть воду в ванной и затопил соседей до
            первого этажа. Заплатят?"
                />
              </h3>
              <p>
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[searchValue]}
                  textToHighlight="Да, но только соседям. Манго не защищает ваши вещи от вас самих, а вот
            соседские — да."
                />
              </p>
            </div>
            <div>
              <h3>
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[searchValue]}
                  textToHighlight="Гости Олега так бурно веселились, что сломали телевизор и дверь в
                  спальню. Заплатят?"
                />
              </h3>
              <p>
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[searchValue]}
                  textToHighlight="Нет. Манго не компенсирует то, что было сломано в вашем доме по вашей
              вине."
                />
              </p>
            </div>
          </>
        )}
        {small && (
          <>
            <Swipeable {...config}>
              <div className="left">
                <h3>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={[searchValue]}
                    textToHighlight="Антон ушел на работу, забыв закрыть воду в ванной и затопил соседей до
            первого этажа. Заплатят?"
                  />
                </h3>
                <p>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={[searchValue]}
                    textToHighlight="Да, но только соседям. Манго не защищает ваши вещи от вас самих, а вот
            соседские — да."
                  />
                </p>
              </div>
            </Swipeable>
            <Dots>
              {slides.map((slide, index) => (
                <Dot
                  onClick={() => handleActiveSlide(index)}
                  key={slide}
                  active={activeSlide === index}
                />
              ))}
            </Dots>
          </>
        )}
      </div>
    </StyledCategoryItem>
  );
};

ElevensBlock.propTypes = {
  searchValue: PropTypes.string.isRequired
};

export default ElevensBlock;
