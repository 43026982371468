import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import vars from '../../utils/styles/vars';
import Button from '../../utils/common/Button';
import closeIcon from '../../assets/icons/close.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import media from '../../utils/helpers/media';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: all 0.3s ease;

  button {
    &:focus {
      outline: none;
    }
  }
`;

const Modal = styled.div`
  height: 432px;
  width: 952px;
  background: white;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 21px 0;
  animation-duration: 0.5s;
  animation-name: zoom;

  ${media.phone`
    height: 304px;
    width: 306px;
    text-align: center;
    padding-top: 50px;
;`}

  h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
    margin-top: 90px;
    font-family: 'Proxima Nova', sans-serif;

    ${media.phone`
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
      margin-top: 0;
      width: 200px;
;`}
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: ${vars.colors.primaryColor};
    cursor: pointer;

    ${media.phone`
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
    ;`}
  }
  .close-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
  }
`;

const NoButton = styled(Button)`
  background: rgba(0, 0, 0, 0);
  color: ${vars.colors.primaryColor};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${vars.colors.primaryColor};
  cursor: pointer;
  box-shadow: none;

  &:hover {
    background: none;
  }

  ${media.phone`
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
    ;`}
`;

const YesButton = styled(Button)`
  height: 56px;
  width: 232px;
  margin: 67px 0 107px 0;

  ${media.phone`
      font-size: 18px;
      line-height: 28px;
      margin: 0;
    ;`}
`;

const CallMeBackModal = ({ closeModal }) => {
  const modalRef = useRef();
  useOutsideClick(modalRef, () => closeModal());
  return (
    <Wrapper>
      <Modal ref={modalRef}>
        <h2>Через минуту вам позвонит наш менеджер Александр</h2>
        <YesButton text="хорошо" onClick={closeModal} />
        <NoButton text="Не надо, я передумал" onClick={closeModal} />
        <button onClick={closeModal} type="button" className="close-button">
          <img src={closeIcon} alt="закрыть окно" aria-label="закрыть окно" />
        </button>
      </Modal>
    </Wrapper>
  );
};

CallMeBackModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default CallMeBackModal;
