export const getKladId = (data, option) => {
  const indexOfOption = data[0].indexOf(option);
  const klad = data[1][indexOfOption];
  return klad;
};

export const getFlatNum = option => {
  const optionsArray = option.split(',');
  const flatArray = optionsArray[optionsArray.length - 1].split(' ');
  const flatNumber = flatArray[flatArray.length - 1];
  return flatNumber;
};

// Метод для выделения вкладок на странице "Условия и выплаты"
export const getScrollValue = (scrollTopPosition, callback) => {
  if (scrollTopPosition > 200) {
    // выделяет вкладку "Условия страхования"
    callback(0);
  }
  if (scrollTopPosition > 626) {
    // выделяет вкладку "Что защищено"
    callback(1);
  }
  if (scrollTopPosition > 1006) {
    // выделяет вкладку "Страховой полис"
    callback(2);
  }
  if (scrollTopPosition > 1928) {
    // выделяет вкладку "Мебель и вещи"
    callback(3);
  }
  if (scrollTopPosition > 2536) {
    // выделяет вкладку "Ремонт"
    callback(4);
  }
  if (scrollTopPosition > 3006) {
    // выделяет вкладку "Соседи"
    callback(5);
  }
  if (scrollTopPosition > 3620) {
    // выделяет вкладку "Личный кабинет"
    callback(6);
  }
  if (scrollTopPosition > 4004) {
    // выделяет вкладку "Страховые случаи"
    callback(7);
  }
};

export const polisLink =
  'https://docviewer.yandex.ru/view/1130000038488991/?*=9kHFjlgPa%2BBZl2hfNv2KyRgF3017InVybCI6InlhLWRpc2stcHVibGljOi8vb3RDUXVoUmk2Ri9pekF4L0M5b0V4TEUwdDNJUEpxWmtzWm12anlFZ01KcmVtcFdNUm0wQ1JaUnZ5TW8rVVJNZnEvSjZicG1SeU9Kb25UM1ZvWG5EYWc9PSIsInRpdGxlIjoiQTQucGRmIiwibm9pZnJhbWUiOmZhbHNlLCJ1aWQiOiIxMTMwMDAwMDM4NDg4OTkxIiwidHMiOjE1NjE1NTQ2NjM1NTIsInl1IjoiNzMxOTAyOTM4MTU0ODE4MDI5NiJ9';
