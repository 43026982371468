import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import media from '../../utils/helpers/media';

const StyledCategoryItem = styled.div`
  h3 {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 27px;

    ${media.phone`  
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
    `};
  }
`;

const SevensBlock = ({ searchValue }) => {
  return (
    <StyledCategoryItem>
      <h3>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Как вы узнаете стоимость вещей и ремонта в моей квартире? Ко мне приедет
          агент?"
        />
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Нам необходимо знать эту стоимость. Цена страховки формируется из
          величины лимитов, которые вы выбрали. Выше лимиты - выше цена. Вещи мы
          оцениваем, если что-то случается и оплачиваем все пострадавшее."
        />
      </p>
      <h3>
        {' '}
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Украли велосипед, который стоял в общем тамбуре. Мне заплатят?"
        />
      </h3>
      <p>
        {' '}
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Нет, вещи, которые находятся за пределами квартиры не защищены."
        />
      </p>
      <h3>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Грабители повредили замок во входной двери, но в квартиру пробраться не
          смогли. Страховка это возместит?"
        />
      </h3>
      <p>
        {' '}
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Да. Входная дверь относится к квартире."
        />
      </p>
      <h3>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="В моей квартире из-за пожара пострадал компьютер за 200 000 Р и орхидея
          за 3 000 Р. Что возместит страховка?"
        />
      </h3>
      <p>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchValue]}
          textToHighlight="Мы выплачиваем максимум 150 000р за одну вещь. Поэтому за компьютер вам
          заплатим 150 000р, а за орхидею целиком."
        />
      </p>
    </StyledCategoryItem>
  );
};

SevensBlock.propTypes = {
  searchValue: PropTypes.string.isRequired
};

export default SevensBlock;
