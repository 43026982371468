import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import media from '../../utils/helpers/media';
import { getScrollValue, polisLink } from '../../utils/helpers/getters';
import useMedia from '../../hooks/useMedia';
import pdfIcon from '../../assets/icons/google.svg';

const labels = [
  { label: 'Условия страхования', to: '#how' },
  { label: 'Что защищено', to: '#protect' },
  { label: 'Страховой полис', to: '#docs' },
  { label: 'Мебель и вещи', to: '#staff' },
  { label: 'Ремонт', to: '#recon' },
  { label: 'Соседи', to: '#nextdoor' },
  { label: 'Личный кабинет', to: '#user' },
  { label: 'Страховые случаи', to: '#cases' }
];

const ListWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-top: -8px;
  margin-bottom: 8px;

  ${media.phone`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    align-items: center;

  `};
`;

const StyledLink = styled(AnchorLink)`
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0.25px;
  margin-bottom: 5px;
  margin-left: 15px;
  cursor: pointer;
  color: ${props => (props.active ? '#818A97' : '#2f3a5b')};
  text-decoration: none;
  &:link,
  &:active {
    color: ${props => (props.active ? '#818A97' : '#2f3a5b')};
  }
  &:last-of-type {
    margin-bottom: 5px;
  }
  ${media.phone`
    font-family: 'Proxima Nova', sans-serif;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    margin-right: 20px;
    color: #2f3a5b;
  `};
`;

const Download = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #794cfa;
  justify-content: start;
  margin-right: 11px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 18px;

  a {
    text-decoration: none;
    color: #794cfa;
    &:focus,
    &:active {
      color: #794cfa;
    }
  }

  .pdf-icon {
    margin-right: 13px;
  }
`;

const DownloadWrapper = styled.div`
  width: auto;
  margin-top: 35px;
  margin-left: 12px;
`;

const LabelsList = () => {
  const [activeLink, setActiveLink] = useState(0);
  const handleActiveLink = link => {
    setActiveLink(link);
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset;
    getScrollValue(scrollTop, setActiveLink);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const large = useMedia('(min-width: 577px)');

  return (
    <ListWrapper>
      {labels.map((label, i) => (
        <StyledLink
          key={label.label}
          href={label.to}
          offset="100"
          onClick={() => {
            handleActiveLink(i);
          }}
          active={i === activeLink}
        >
          {label.label}
        </StyledLink>
      ))}
      {large && (
        <DownloadWrapper>
          <Download>
            {' '}
            <a href={`${polisLink}`}>
              <img
                src={pdfIcon}
                alt="icon for download file"
                className="pdf-icon"
              />
              условия страхования
            </a>
          </Download>
          <Download>
            <a href={`${polisLink}`}>
              {' '}
              <img
                src={pdfIcon}
                alt="icon for download file"
                className="pdf-icon"
              />
              Страховой полис
            </a>
          </Download>
        </DownloadWrapper>
      )}
    </ListWrapper>
  );
};

export default LabelsList;
