import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import checkIcon from '../../assets/icons/check.png';
import not from '../../assets/icons/not.png';
import media from '../../utils/helpers/media';

const StyledCategoryItem = styled.div`
  .label {
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;

    &.label--bottom {
      margin-bottom: 10px;
    }
  }

  .text {
    font-family: 'Proxima Nova', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    width: 304px;

    ${media.phone`
      width: 100%;
      font-size: 15px;
    ;`}

    &.text--bottom {
      margin-top: 15px;
    }
  }

  .top {
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100%;

    ${media.phone`
      flex-direction: column;
    ;`}

    .top--sub {
      margin-right: 50px;
      ${media.phone`
        margin-right: 0;
        margin-bottom: 27px;
    ;`}
    }

    div {
      display: flex;
      align-items: start;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
    }
    img {
      width: 34px;
      height: 34px;
      margin-right: 12px;
    }
  }

  .bottom {
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: end;
    width: 100%;
  }
`;

const SecondBlock = ({ searchValue }) => {
  return (
    <StyledCategoryItem>
      <div className="top">
        <div className="top--sub">
          <h5 className="label">
            <img src={checkIcon} alt="#" />
            Защищены
          </h5>
          <p className="text">
            <Highlighter
              highlightClassName="highlight"
              searchWords={[searchValue]}
              textToHighlight="Все вещи внутри жилья: мебель, электроннобытовая техника, одежда и
                  украшения, до 150 000 ₽ за каждую вещь."
            />
          </p>
          <p className="text text--bottom">
            <Highlighter
              highlightClassName="highlight"
              searchWords={[searchValue]}
              textToHighlight="Весь ремонт внутри квартиры, окна, стены, полы, трубы и батареи,
              включая инженерные коммуникации."
            />
          </p>
        </div>
        <div>
          <h5 className="label">
            <img src={not} alt="#" />
            За что не платим
          </h5>
          <p className="text">
            <Highlighter
              highlightClassName="highlight"
              searchWords={[searchValue]}
              textToHighlight="Вещи за пределами вашей квартиры, наличные деньги, поломки бытовой
              техники, бетонный каркас и несущие стены."
            />
          </p>
        </div>
      </div>
    </StyledCategoryItem>
  );
};

SecondBlock.propTypes = {
  searchValue: PropTypes.string.isRequired
};

export default SecondBlock;
