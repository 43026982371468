import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import LabelList from './LabelsList';
import searchIcon from '../../assets/icons/magnify.png';
import closeIcon from '../../assets/icons/close.png';
import useMedia from '../../hooks/useMedia';
import media from '../../utils/helpers/media';
import Heading from '../../utils/common/Heading';
import Hero from './Hero';
import useOutsideClick from '../../hooks/useOutsideClick';
import data from './data';
import WithSearchedValue from './WithSearchedValue';
import MobileLabelList from './MobileLabelList';

const SectionWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
  height: ${props => (props.search ? '100%' : '5000px')};
  position: relative;

  ${media.phone`
    flex-direction: column;
    max-width: 576px;
    margin-top: 0;
    height: 100%;
    
  `};
`;

const Accordians = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-left: 55px;
  width: 700px;
  position: relative;

  ${media.phone`
    width: -webkit-fill-available;
    margin-left: 0;
    padding: 0 15px;
  `};
`;

const SearchInputIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  height: 65px;
  position: relative;
  margin-bottom: 18px;
  width: 260px;

  ${media.phone`
    width: 100%;
    justify-content: center;
    margin: 25px auto 0 auto;
  `};

  input {
    width: 258px;
    height: 56px;
    background: white;
    border: 1px solid #794cfa;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    padding: 16px;
    transition: all 0.3s ease;

    @media (max-width: 415px) {
      width: 350px;
      border: 1px solid #d9dce6;
    }
    @media (max-width: 376px) {
      width: 334px;
      border: 1px solid #d9dce6;
    }
    @media (max-width: 320px) {
      width: 290px;
      border: 1px solid #d9dce6;
    }
    &:focus {
      outline: none;
      border: 1px solid #794cfa;
    }
  }

  .search-icon {
    cursor: pointer;
    transition: all 0.3s ease;
    position: absolute;
    color: red;
    order: 1;
    top: 21%;
    right: 5%;
    width: 35px;
    height: 40px;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }

    img {
      width: 100%;
    }

    ${media.phone`
      position: absolute;
      top: 21%;
      right: 5%;
    `};
  }
`;

const AccordianWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;

  ${media.phone`
    align-items: center;
  `};
`;

const CategoryHeading = styled.h3`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
  font-weight: 600;

  ${media.phone`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.25px;
  `};
`;

const Wrapper = styled.div`
  position: sticky;
  top: 12%;

  ${media.phone`
    top: 9.45%;
    z-index: 10;
    position: sticky;
    background: #ffffff;
    padding: 0 20px;
    width: 100%;
    margin-bottom: 20px;
  `};
`;

const MainSection = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isInputShown, setIsInputShown] = useState(false);
  const [isHeroShown, setIsHeroShown] = useState(true);
  const formRef = useRef();
  useOutsideClick(formRef, () => {
    setIsInputShown(false);
    setSearchValue('');
    setIsHeroShown(true);
  });

  const filteredData = data.filter(
    paragraph =>
      paragraph.text &&
      paragraph.category.toLowerCase().includes(searchValue.toLowerCase())
  );

  const showSearchInput = () => {
    setIsInputShown(!isInputShown);
  };

  const handleChange = e => {
    setSearchValue(e.target.value);
    if (e.target.value !== '') {
      setIsHeroShown(false);
    } else {
      setIsHeroShown(true);
    }
  };

  const clearInput = () => {
    setSearchValue('');
    setIsHeroShown(true);
  };

  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');

  return (
    <>
      <SectionWrapper search={searchValue !== ''}>
        <Wrapper>
          <SearchInputIconWrapper ref={formRef}>
            {large && (
              <>
                {!isInputShown && (
                  <button
                    type="button"
                    onClick={showSearchInput}
                    className="search-icon"
                  >
                    <img src={searchIcon} alt="#" area-label="search icon" />
                  </button>
                )}
                {searchValue !== '' && (
                  <button
                    type="button"
                    onClick={clearInput}
                    className="search-icon"
                  >
                    <img src={closeIcon} alt="#" area-label="search icon" />
                  </button>
                )}
                {isInputShown && (
                  <input
                    type="text"
                    value={searchValue}
                    onChange={e => handleChange(e)}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                )}
              </>
            )}

            {small && (
              <>
                <input
                  type="text"
                  value={searchValue}
                  onChange={e => handleChange(e)}
                />
                {searchValue !== '' ? (
                  <button
                    className="search-icon"
                    onClick={clearInput}
                    type="button"
                  >
                    <img src={closeIcon} alt="#" area-label="search icon" />
                  </button>
                ) : (
                  <button
                    className="search-icon"
                    onClick={showSearchInput}
                    type="button"
                  >
                    <img src={searchIcon} alt="#" area-label="clear input" />
                  </button>
                )}
              </>
            )}
          </SearchInputIconWrapper>
          {large && <LabelList />}
          {small && <MobileLabelList />}
        </Wrapper>
        <Accordians>
          {large && <Heading text="Условия и выплаты" center={false} />}
          {isHeroShown && <Hero />}
          {filteredData.length === 0 && <p>Ничего не найдено</p>}
          {data &&
            filteredData.map(accord => (
              <AccordianWrapper key={accord.id}>
                <CategoryHeading id={accord.id}>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={[searchValue]}
                    textToHighlight={accord.category}
                  />
                </CategoryHeading>
                <WithSearchedValue
                  component={accord.component}
                  searchValue={searchValue}
                  text={accord.text}
                />
              </AccordianWrapper>
            ))}
        </Accordians>
      </SectionWrapper>
    </>
  );
};

export default MainSection;
